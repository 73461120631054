<template>
  <div class="flex_column full-content">
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      @update:pagination="handlePaginationChange"
    >
      <template #deliverStatus="{row}">
        {{ filterLabel(row.deliverStatus, DELIVER_STATUS) }}
      </template>
      <template #action="{row}">
        <nh-button
          v-if="waveOrderStatus !== DISTRIBUTE.value"
          type="text"
          :loading="loading.handleRemove"
          @click="handleRemove(row)"
        >
          移除订单
        </nh-button>
      </template>
    </PlTable>
  </div>
</template>
<script>
import moment from 'moment';
import loadingMixin from '@thales/loading';
import { YMDHMS } from '@/constant/timeFormat';
import { getDeliverOrderFromWaveOrder, removeWaveOrder } from '../../api';
import { DISTRIBUTE } from '../../../fileds';
import { DICTIONARY, TABLECOLUMN, DELIVER_STATUS } from './fileds';

export default {
  name: 'WaveOrderDetailOrder',
  mixins: [loadingMixin],
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    waveOrderStatus: {
      type: String,
      default: '',
    },
  },
  emits: ['onSuccess'],
  data() {
    return {
      DICTIONARY,
      TABLECOLUMN,
      DELIVER_STATUS,
      DISTRIBUTE,
      tableData: [],
      loading: {
        getTableData: false,
        handleRemove: false,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    filterLabel(val, arr) {
      const filterArr = arr && arr.find((item) => item.value === val);
      return filterArr ? filterArr.label : '';
    },
    async init() {
      this.getTableData();
    },
    handlePaginationChange(pagination) {
      this.pagination = {
        ...this.pagination,
        ...pagination,
      };
      this.getTableData();
    },
    async getTableData() {
      const result = await getDeliverOrderFromWaveOrder(this.pagination, { id: this.id });
      this.tableData = result.records.map((item) => ({
        ...item,
        createTime: item.createTime && moment(item.createTime).format(YMDHMS),
        bookingTime: item.bookingTime && moment(item.bookingTime).format(YMDHMS),
        deliverTime: item.deliverTime && moment(item.deliverTime).format(YMDHMS),
      }));
      this.pagination.total = result.total;
      this.pagination.page = result.page;
      this.pagination.size = result.size;
    },
    async handleRemove(row) {
      await this.$confirm('是否确认移除该订单？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });
      await removeWaveOrder({ wareOrderId: this.id, deliverOrderId: row.id });
      this.$message({
        type: 'success',
        message: '移除成功!',
      });
      this.pagination.page = 1;
      this.$emit('onSuccess');
      this.getTableData();
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
