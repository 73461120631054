<template>
  <nh-drawer
    v-model="dialogVisible"
    v-loading="loading.init"
    :before-close="handleClose"
    destroy-on-close
    :title="title"
    size="704px"
    :track="{
      name_zh: `出库管理/波次管理/抽屉-${batchTrack}任务下发`,
      trackName: $route.path
    }"
  >
    <template v-if="title === SHOW_COMPONENTS.taskDistribution">
      <div class="alert">
        <i class="iconfont iconjinggao" />
        注：任务下发后中途不支持修改波次和订单.
      </div>
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
      >
        <el-form-item
          label="是否指定分配规则:"
        >
          <el-radio-group v-model="checked" @change="handleChecked">
            <el-radio
              v-for="item in RULE_STOCK"
              :key="item.value"
              :label="item.value"
              size="small"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="checked"
          prop="ruleStockAllocationId"
        >
          <template #label>
            <span />
          </template>
          <el-select
            v-model="form.ruleStockAllocationId"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in ruleStockAllocationList"
              :key="item.id"
              :label="item.ruleCode + '|'+ item.description"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <el-form
      v-if="title === SHOW_COMPONENTS.contiune"
      ref="ruleFormContiune"
      :model="form"
      :rules="rules"
    >
      <el-form-item
        label="是否分拣线分拣:"
        prop="automationPush"
      >
        <el-radio-group v-model="form.automationPush" @change="handleChangeAuto">
          <el-radio
            v-for="item in AUTOMATION_PUSH"
            :key="item.label + item.value"
            :label="item.value"
            size="small"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <nh-drawer-container
        v-if="form.automationPush !== AUTOMATION_PUSH[1].value"
        :line="['bottom']"
        title="自动分拣线分拣范围"
      >
        <el-form-item
          label="越库:"
          prop="pushScope.crossDocking"
        >
          <el-checkbox-group
            v-model="form.pushScope.crossDocking"
          >
            <el-checkbox
              v-for="item in CROSS_DOCKING"
              :key="item.label + item.value"
              :label="item.value"
              size="small"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          label="温层:"
          prop="pushScope.temperatureLayers"
        >
          <el-checkbox-group v-model="form.pushScope.temperatureLayers">
            <el-checkbox
              v-for="item in TEMPERATURE_LAYERS"
              :key="item.label + item.value"
              :label="item.value"
              size="small"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          v-loading="loading.getShippers"
          label="货主:"
          prop="pushScope.shipperIds"
          size="small"
        >
          <el-checkbox-group v-model="form.pushScope.shipperIds">
            <el-checkbox
              v-for="item in shippers"
              :key="item.id"
              :label="item.id"
              class="shipper-checkbox"
            >
              {{ item.shipperName }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </nh-drawer-container>
      <el-form-item
        prop="checked"
        label="是否指定分配规则:"
      >
        <el-radio-group v-model="checked" @change="handleChecked">
          <el-radio
            v-for="item in RULE_STOCK"
            :key="item.value"
            :label="item.value"
            size="small"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="checked"
        prop="ruleStockAllocationId"
      >
        <template #label>
          <span />
        </template>
        <el-select
          v-model="form.ruleStockAllocationId"
          placeholder="请选择"
          filterable
          size="small"
        >
          <el-option
            v-for="item in ruleStockAllocationList"
            :key="item.id"
            :label="item.ruleCode + '|'+ item.description"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <div class="footer">
        <nh-button
          type="grey"
          @click="handleClose"
        >
          取 消
        </nh-button>
        <nh-button
          v-loading="loading.onSubmitForm"
          type="primary"
          @click="onSubmitForm"
        >
          确 定
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>

<script>
import loadingMixin from '@thales/loading';
import {
  submitWaveOrder, getShippers, deliverFlowMatchOne,
  getRuleStockAllocation,
  lackAllocateSubmit,
} from '../api';
import { DICTIONARY } from '../fileds';
import {
  AUTOMATION_PUSH, CROSS_DOCKING, TEMPERATURE_LAYERS, RULE_STOCK,
  SHOW_COMPONENTS,
} from './constant';

export default {
  name: 'OutWarehouseWaveOrderDrawer',
  mixins: [loadingMixin],
  props: {
    batchTrack: {
      type: String,
      default: '',
    },
  },
  emits: ['handleSuccess'],
  data() {
    const ruleStockAllocation = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择'));
      }
      return callback();
    };
    return {
      title: null,
      dialogVisible: false,
      SHOW_COMPONENTS,
      RULE_STOCK,
      checked: false,
      ruleStockAllocationList: [],
      AUTOMATION_PUSH,
      CROSS_DOCKING,
      TEMPERATURE_LAYERS,
      shippers: [],
      id: '',
      ids: [],
      form: {
        automationPush: null,
        pushScope: {
          crossDocking: [],
          temperatureLayers: [],
          shipperIds: [],
        },
        ruleStockAllocationId: undefined,
      },
      rules: {
        automationPush: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        'pushScope.crossDocking': [
          {
            type: 'array', required: true, message: '请至少选择一个', trigger: 'change',
          },
        ],
        'pushScope.temperatureLayers': [
          {
            type: 'array', required: true, message: '请至少选择一个', trigger: 'change',
          },
        ],
        'pushScope.shipperIds': [
          {
            type: 'array', required: true, message: '请至少选择一个', trigger: 'change',
          },
        ],
        ruleStockAllocationId: [{ validator: ruleStockAllocation, trigger: 'change' }],
      },
      loading: {
        init: false,
        onSubmitForm: false,
        getShippers: false,
      },
    };
  },
  methods: {
    async init(row) {
      const { ids } = row;
      this.ids = ids;
      const { configWavePicking } = await deliverFlowMatchOne({
        warehouseCode: row.warehouseCode,
        deliverType: row.deliverType,
      });
      if (ids.length > 1) { // 批量下发
        this.title = SHOW_COMPONENTS.taskDistribution;
        // 启用分拣设备的单据不可批量任务下发
        if (configWavePicking.automationPick === DICTIONARY.ENABLED) {
          this.$message({ type: 'warning', message: '请选择未启用分拣设备的波次!' });
          return;
        }
      } else {
        this.ruleStockAllocationList = await getRuleStockAllocation();
        if (configWavePicking.automationPick === DICTIONARY.ENABLED
      && configWavePicking.automationPush !== DICTIONARY.ENABLED) {
        // 启用分拣设备且否分拣线分拣
          this.getShippers(ids[0]);
          this.title = SHOW_COMPONENTS.contiune;
        } else {
          this.title = SHOW_COMPONENTS.taskDistribution;
        }
      }
      this.dialogVisible = true;
    },
    async getShippers(waveId) {
      this.shippers = await getShippers({ waveId });
    },
    handleChangeAuto(val) {
      this.form = {
        automationPush: val,
        pushScope: {
          crossDocking: [],
          temperatureLayers: [],
          shipperIds: [],
        },
        ruleStockAllocationId: undefined,
      };
      this.$refs.ruleFormContiune.resetFields();
    },
    handleChecked(val) {
      if (!val) {
        this.form.ruleStockAllocationId = undefined;
      }
    },
    async getSubmitParams() {
      let form = {};
      if (this.title === SHOW_COMPONENTS.taskDistribution) {
        await this.$refs.ruleForm.validate();
        form = { ruleStockAllocationId: this.form.ruleStockAllocationId };
      } else {
        await this.$refs.ruleFormContiune.validate();
        form = this.form;
      }
      return form;
    },
    async onSubmitForm() {
      const form = await this.getSubmitParams();
      if (this.ids.length > 1) { // 批量下发
        await submitWaveOrder({
          waveOrderIds: this.ids,
          ...form,
        });
        this.deliverSuccess();
      } else {
        const req = await submitWaveOrder({
          waveOrderIds: this.ids,
          ...form,
        });
        if (typeof req === 'boolean' && req) {
          this.deliverSuccess();
          return;
        }
        const { success, code, msg } = req;
        if (success) {
          this.deliverSuccess();
        } else {
          this.handleCheckFail(code, msg);
        }
      }
    },
    handleCheckFail(code, msg) {
      if (code === 'STOCK_ALLOCATE_FAIL') {
        this.handleStockDestribution(msg);
      } else {
        this.$message({
          type: 'error',
          message: msg,
        });
      }
    },
    // 处理缺货分配的情况
    async handleStockDestribution(msg) {
      try {
        const msgHtml = `<p class="tips">
            当前波次存在缺货情况，点击【缺货分配】可根据现有可用库存进行分配
          </p>
          <p class="error-msg">
            ${msg}
          </p>`;
        await this.$confirm(msgHtml, '提示', {
          customClass: 'out-stock-confirm',
          dangerouslyUseHTMLString: true,
          confirmButtonText: '缺货分配',
          cancelButtonText: '取消',
        });
      } catch (error) {
        this.$message({
          type: 'info',
          message: '已取消',
        });
        return;
      }
      const form = await this.getSubmitParams();
      await lackAllocateSubmit({
        waveOrderIds: this.ids,
        ...form,
      });
      this.deliverSuccess();
    },
    deliverSuccess() {
      this.$message({ type: 'success', message: '操作成功' });
      this.$emit('handleSuccess');
      this.handleClose();
    },
    handleClose() {
      this.checked = false;
      this.ids = [];
      if (this.title === SHOW_COMPONENTS.taskDistribution) {
        this.$refs.ruleForm.resetFields();
      } else {
        this.$refs.ruleFormContiune.resetFields();
      }
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.alert {
  box-sizing: border-box;
  margin-bottom: 16px;
  padding-left: 16px;
  line-height: 48px;
  background-color: #f9f1ec;
  border: 1px solid #fbbb98;
  border-radius: 4px;
  font-size: 14px;
  color: #fe5e08;

  .iconfont {
    margin-right: 8px;
  }
}

.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}

.shipper-checkbox {
  display: block;
}
</style>
<style lang="scss">
.out-stock-confirm {
  .el-message-box {
    width: 450px;
  }

  .error-msg {
    margin-top: 15px;
    color: #f21313;
    max-height: 200px;
    overflow: auto;
  }
}
</style>
