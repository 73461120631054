import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import { DELIVER_TYPE, DELIVER_TYPE_ENUM } from '@/views/out-warehouse/deliver-order/constant';

const DICTIONARY = {
  WAVE_ORDER_NO: 'waveOrderNo',
  WAVE_ORDER_STATUS: 'waveOrderStatus',
  WAVE_ORDER_Goods_STATUS: 'waveOrderGoodsStatus',
  ORDER_GOODS_STATUS_NORMAL: 'NORMAL',
  REPLENISHMENT: 'REPLENISHMENT',
  ENABLED: 'ENABLED',
};
const WAITING_DISTRIBUTE = {
  value: 'WAITING_DISTRIBUTE',
  code: 'WAITING_DISTRIBUTE',
  label: '待下发',
};
const DISTRIBUTE = {
  value: 'DISTRIBUTE',
  code: 'DISTRIBUTE',
  label: '已下发',
};

const DISTRIBUTING = {
  value: 'DISTRIBUTING',
  code: 'DISTRIBUTING',
  label: '批量下发中',
};

const PICK_COMPLETED = {
  value: 'PICK_COMPLETED',
  code: 'PICK_COMPLETED',
  label: '拣货完成',
};
export const PICKING = {
  value: 'PICKING',
  code: 'PICKING',
  label: '拣货中',
};
const CANCELED = {
  value: 'CANCELED',
  code: 'CANCELED',
  label: '已取消',
};
const ORDER_STATUS = [
  WAITING_DISTRIBUTE,
  DISTRIBUTING,
  PICKING,
  PICK_COMPLETED,
  DISTRIBUTE,
  CANCELED,
];
const ORDER_GOODS_STATUS = [{
  code: 'NORMAL',
  label: '正常 ',
}, {
  code: 'STOCK_OUT',
  label: '缺货',
}, {
  code: 'REPLENISHMENT',
  label: '补货',
}];

export {
  PICK_COMPLETED,
  DICTIONARY,
  DISTRIBUTE,
  WAITING_DISTRIBUTE,
  DISTRIBUTING,
  ORDER_STATUS,
  ORDER_GOODS_STATUS,
};
export const TABLECOLUMN = [
  {
    type: 'selection',
    width: 50,
  }, {
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '波次号',
    prop: 'waveOrderNo',
    minWidth: 160,
  }, {
    label: '状态',
    prop: 'waveOrderStatus',
    minWidth: '110px',
  }, {
    label: '出库单类型',
    prop: 'deliverType',
    formatter: (row) => DELIVER_TYPE_ENUM[row.deliverType],
    minWidth: 120,
  }, {
    label: '货主',
    prop: 'shipperList',
    minWidth: 150,
  }, {
    label: '订单数',
    prop: 'deliverOrderNum',
  }, {
    label: '任务数',
    prop: 'pickTaskNum',
  }, {
    label: '品类数量',
    prop: 'goodsCategoryNum',
    minWidth: 80,
  }, {
    label: '商品数量',
    prop: 'goodsNum',
    minWidth: 80,
  }, {
    label: '最早提货时间',
    prop: 'earliestTakeDeliveryTime',
    ...DateTime,

  }, {
    label: '创建人',
    prop: 'createUserName',
    minWidth: 150,
  }, {
    label: '拣货单打印次数',
    prop: 'ext.pickPrintCount',
    minWidth: 150,
    formatter: (row) => (row.ext.pickPrintCount ? row.ext.pickPrintCount : 0),
  }, {
    label: '出库单打印次数',
    prop: 'ext.deliverPrintCount',
    minWidth: 150,
    formatter: (row) => (row.ext.deliverPrintCount ? row.ext.deliverPrintCount : 0),
  }, {
    label: '波次创建时间',
    prop: 'createTime',
    ...DateTime,

  }, {
    label: '波次下发时间',
    prop: 'distributeTime',
    ...DateTime,

  }, {
    label: '备注',
    prop: 'notes',
    minWidth: '120px',
    formatter: (row) => (row.errorInfo?.exceptionType === 'STOCK_ALLOCATE_FAIL' ? '波次缺货，需对该波次单独下发' : ''),

  }, {
    label: '操作',
    prop: 'action',
    fixed: 'right',
    width: 320,
  }];
export const serchFields = [
  {
    label: '波次号',
    prop: 'waveOrderNo',
    component: FormType.INPUT,
  }, {
    label: '波次状态',
    prop: 'waveOrderStatus',
    component: FormType.SELECT,
    options: ORDER_STATUS,
  }, {
    label: '波次创建时间',
    prop: 'createTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
    },

  }, {
    label: '波次下发时间',
    prop: 'distributeTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
    },
  },
  {
    label: '货主',
    prop: 'shipperId',
  },
  {
    label: '创建人',
    prop: 'createUserName',
    component: FormType.INPUT,
  },
  {
    label: '出库单类型',
    prop: 'deliverType',
    component: FormType.SELECT,
    options: DELIVER_TYPE,
  },
];
export const EXPORT_TYPE = {
  ORDER_TYPE: 'ORDER_TYPE',
  GOODS_TYPE: 'GOODS_TYPE',
};
