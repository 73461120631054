import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:波次单 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12288
 */
export function getWaveOrders(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/page', data, {
    params,
  });
}

/**
 * @description:波次任务下发
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12296
 */
export function submitWaveOrder(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/submit', data);
}
/**
 * @description:波次取消
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12298
 */
export function cancelWaveOrder(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/cancel', data);
}
/**
 * @description:分页获取波次单对应出库单列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12284
 */
export function getDeliverOrderFromWaveOrder(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/page_deliver_of_wave', data, {
    params,
  });
}
/**
 * @description:通过仓库号以及出库类型匹配规则
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14543
 */
export function deliverFlowMatchOne(params) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_deliver_flow/match_one', params);
}
/**
 * @description: 查询波次单涉及到的货主
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14945
 */
export function getShippers(params) {
  return wmsPlusHttp.get('/warehouse_management_system/wave_order/shippers', {}, { params });
}
/**
 * @description: 根据下架规则Ids查询下架规则列表(不传参数查询所有)
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11948
 */
export function getRuleStockAllocation() {
  return wmsPlusHttp.post('/warehouse_management_system/rule_stock_allocation/list', {});
}
/**
 * @description: 缺货分配
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/21339
 */
export function lackAllocateSubmit(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/lack_allocate/submit', data);
}
/**
 * @description: 缺货货品列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/21347
 */
export function lackGoodsList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/goods_detail/lack_goods/list', data);
}
/**
 * @description: 缺货货品追加下发
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/21349
 */
export function lackLockGoodsReallocate(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/goods_detail/lack_goods/reallocate', data);
}
/**
 * @description:导出缺货明细
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16107
 */
export function waveOrderExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/wave_order/export/goods/lack', data, { fileName: '缺货明细', extension: 'xlsx' });
}
/**
 * @description:批量拣货确认
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17141
 */
export function batchPickConfirm(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/batch_pick_confirm', data);
}
/**
 * @description:子任务详情 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11780
 */
export function subtasksPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/subtasks/page', data, {
    params,
  });
}
/**
 * @description:单个拣货确认
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17207
 */
export function singlePickConfirm(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/single_pick_confirm', data);
}
/**
 * @description:判断该波次单是否未总拣完成的任务单号
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17637
 */
export function subtasksUnfinishedTasks(params) {
  return wmsPlusHttp.get('/warehouse_management_system/subtasks/unfinished_tasks', {}, { params });
}
/**
 * @description:波次单驳回
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17827
 */
export function waveOrderReject(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/reject', data);
}
/**
 * @description:根据 波次号+指定抄码方式 查询抄码数据
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18583
 */
export function appointTypeQuery(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_copy_code/appoint_type/query', data);
}
/**
 * @description:根据 波次号 查询抄码数据
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18585
 */
export function waveCopyCodeQuery(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_copy_code/query', data);
}
/**
 * @description: 抄码 确认逻辑
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18587
 */
export function waveCopyCodeConfirm(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_copy_code/confirm', data);
}
