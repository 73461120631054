<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    title="波次详情"
    size="1111px"
    :track="{
      name_zh: `出库管理/波次管理/抽屉-详情`,
      trackName: $route.path
    }"
  >
    <div v-loading="loading.init" class="flex_column full-content">
      <nh-dialog-form>
        <nh-dialog-form-item
          v-for="item in WAVE_DETAIL"
          :key="item.keyword"
          :label="item.label"
          :span="8"
        >
          {{ waveList[item.keyword] }}
        </nh-dialog-form-item>
      </nh-dialog-form>
      <el-tabs v-model="activeName" class="flex_column buttom-box">
        <el-tab-pane
          :label="ORDER_DETAILS.label"
          :name="ORDER_DETAILS.value"
          class="buttom-box"
        >
          <order-details
            v-if="activeName === ORDER_DETAILS.value"
            :id="id"
            :wave-order-status="waveList.waveOrderStatus"
            @onSuccess="init"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="TASK_DETAILS.label"
          :name="TASK_DETAILS.value"
          class="buttom-box"
        >
          <task-details v-if="activeName === TASK_DETAILS.value" :id="id" />
        </el-tab-pane>
        <el-tab-pane
          :label="GOODS_DETAILS.label"
          :name="GOODS_DETAILS.value"
          class="buttom-box"
        >
          <goods-details v-if="activeName === GOODS_DETAILS.value" :id="id" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </nh-drawer>
</template>

<script>
import loadingMixin from '@thales/loading';

import moment from 'moment';
import { YMDHMS } from '@/constant/timeFormat';
import {
  WAVE_DETAIL, ORDER_DETAILS, TASK_DETAILS, GOODS_DETAILS,
} from './fileds';
import orderDetails from './components/orderDetails/index.vue';
import taskDetails from './components/taskDetails/index.vue';
import goodsDetails from './components/goodsDetails/index.vue';
import { getWaveOrderDetail } from './api';

export default {
  name: 'WaveOrderDetail',
  components: {
    orderDetails,
    taskDetails,
    goodsDetails,
  },
  mixins: [loadingMixin],
  emits: ['onSuccess'],
  data() {
    return {
      drawerVisible: false,
      WAVE_DETAIL,
      ORDER_DETAILS,
      TASK_DETAILS,
      GOODS_DETAILS,
      title: '',
      id: null,
      activeName: ORDER_DETAILS.value,
      waveList: {},
      loading: {
        init: false,
      },
    };
  },
  methods: {
    async init(id) {
      this.id = id;
      this.drawerVisible = true;
      this.activeName = ORDER_DETAILS.value;
      this.waveList = {};
      const resp = await getWaveOrderDetail({ id: this.id });
      this.waveList = {
        ...resp,
        createTime: resp.createTime && moment(resp.createTime).format(YMDHMS),
        distributeTime: resp.distributeTime && moment(resp.distributeTime).format(YMDHMS),
        pickFinishTime: resp.pickFinishTime && moment(resp.pickFinishTime).format(YMDHMS),
      };
    },
    handleClose() {
      this.$emit('onSuccess');
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.page {
  :deep(.el-tabs__content) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.list-container-component {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.buttom-box {
  flex: 1;
}

.base-label {
  color: #606266;
}

.base-value {
  font-weight: 600;
  color: #171717;
}

.base {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 0 16px;

  & > div {
    width: 20%;
    min-width: 140px;
    padding: 8px 0;
  }

  &::after {
    content: '';
  }
}

.create_base {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;

  & > div {
    box-sizing: border-box;
    width: 50%;
    padding: 8px 16px;
  }
}
</style>
