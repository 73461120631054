import {
  ORDER_GOODS,
  PICK_GOODS,
  SUPPLEMENTARY_NUMBER,
} from './constant';

const recordType = [{
  value: ORDER_GOODS,
  label: '订单+货品',
}, {
  value: PICK_GOODS,
  label: '波次+货品',
}];
const formRaido = {
  [ORDER_GOODS]: 'orderCopyCodeDataList',
  [PICK_GOODS]: 'waveCopyCodeDataList',
};
const tableColumn = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  minWidth: 160,
}, {
  label: '商品名称',
  prop: 'goodsName',
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 150,
},
{
  label: '预约总数量',
  prop: 'bookingAmount',
  minWidth: 150,
},
{
  label: '库存分配量',
  prop: 'allocateAmount',
  minWidth: 150,
},
{
  label: '辅助数量',
  prop: SUPPLEMENTARY_NUMBER,
  minWidth: 150,
}];

export {
  recordType,
  formRaido,
  tableColumn,
};
