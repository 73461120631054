import unitNumber from '@/utils/unitNumber';

export const DICTIONARY = {
  QUALITY: 'quality',
  SHIPPER_GOODS_CODE: 'shipperGoodsCode',
  crossDocking: '越库中',
  replenishment: '补货中',
};
function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}
export const TABLECOLUMN = (GOODS_QUALITY) => [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货主货品编号',
  prop: 'shipperGoodsCode',
  width: 180,
}, {
  label: '商品名称',
  prop: 'goodsName',
  minWidth: 100,
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 80,
}, {
  label: '货品质量',
  prop: 'quality',
  width: 80,
  formatter: ({ quality }) => filterLabel(quality, GOODS_QUALITY),
}, {
  label: '指定批次',
  prop: 'batchNo',
  width: 120,
}, {
  label: '指定库位',
  prop: 'storehouseCode',
  width: 120,
}, {
  label: '预约数量',
  prop: 'bookingNum',
  minWidth: 120,
  formatter: (row) => unitNumber.calculate({ ...row, smallUnitNumber: row.bookingNum }).txt,
}, {
  label: '分配数量',
  prop: 'allocateNum',
  minWidth: 120,
  formatter: (row) => unitNumber.calculate({ ...row, smallUnitNumber: row.allocateNum }).txt,

}, {
  label: '拣货数量',
  prop: 'pickNum',
  minWidth: 120,
  formatter: (row) => unitNumber.calculate({ ...row, smallUnitNumber: row.pickNum }).txt,
},
{
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 100,
},
];
