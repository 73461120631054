import { appointTypeQuery } from '../../../api';
import { recordType } from '../fileds';

/**
 * 根据抄码方式请求 抄码数据
 * @param {*} radio 抄码方式
 * @param {*} waveOrderId 波次号
 * @returns formData
 */
export default async function changeRadio(radio, waveOrderId) {
  const { waveCopyCodeResp, orderCopyCodeResp } = await appointTypeQuery({
    waveOrderId,
    copyCodeTypeEnum: radio,
  });
  const form = {};
  if (radio === recordType[0].value) {
    form.orderCopyCodeDataList = orderCopyCodeResp.orders;
  } else {
    form.waveCopyCodeDataList = waveCopyCodeResp.details;
    form.isExistsDeliveredOrder = waveCopyCodeResp.isExistsDeliveredOrder;
  }
  return form;
}
