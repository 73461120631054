import { ORDER_GOODS } from '../constant';

/**
 * 根据错误信息，设置对应单号错误提示
 * @param {*} formData
 * @param {*} waveErrorDataList
 * @param {*} orderErrorDataList
 * @returns formData
 */
export function setError(
  formData,
  waveErrorDataList,
  orderErrorDataList,
) {
  const data = {};
  if (formData.copyCodeType === ORDER_GOODS) {
    data.orderCopyCodeDataList = formData.orderCopyCodeDataList.map((item) => {
      const row = {};
      row.details = item.details.map((listItem) => {
        let error = null;
        for (let i = 0, l = orderErrorDataList.length; i < l; i += 1) {
          if (listItem.deliverOrderId === orderErrorDataList[i].deliverOrderId
            && listItem.deliverOrderDetailId === orderErrorDataList[i].deliverOrderDetailId) {
            error = ' ';
            break;
          } else {
            error = null;
          }
        }
        return {
          ...listItem,
          error,
        };
      });
      return {
        ...item,
        ...row,
      };
    });
  } else {
    // 计算波次抄码明细 合计
    data.waveCopyCodeDataList = formData.waveCopyCodeDataList.map((item) => {
      let error = null;
      for (let i = 0, l = waveErrorDataList.length; i < l; i += 1) {
        if (item.waveOrderId === waveErrorDataList[i].waveOrderId
          && item.waveOrderGoodsDetailId === waveErrorDataList[i].waveOrderGoodsDetailId) {
          error = ' ';
          break;
        } else {
          error = null;
        }
      }
      return {
        ...item,
        error,
      };
    });
  }
  return {
    ...formData,
    ...data,
  };
}

/**
 * 判断输入框是否存在错误提示
 * @param {*} formData
 * @returns Boolean
 */
export function rulesError(formData) {
  let flag = true;
  if (formData.copyCodeType === ORDER_GOODS) {
    formData.orderCopyCodeDataList.forEach((item) => {
      item.details.forEach((listItem) => {
        if (listItem.error === ' ') {
          flag = false;
        }
      });
    });
  } else {
    // 波次抄码明细
    formData.waveCopyCodeDataList.forEach((item) => {
      if (item.error === ' ') {
        flag = false;
      }
    });
  }
  return flag;
}
