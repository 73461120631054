import { FormType } from '@/constant/form';

export const DICTIONARY = {
  ALLOCATION: 'allocation',
};
export const serchFields = {
  goodsCode: null,
  goodsName: null,
  typeCode: null,
  zoneName: null,
  locationCode: null,
};
export const formFileds = [{
  label: '商品条码：',
  prop: 'goodsCode',
  component: FormType.INPUT,
  componentAttrs: {
    disabled: true,
  },
}, {
  label: '商品名称：',
  prop: 'goodsName',
  component: FormType.INPUT,
  componentAttrs: {
    disabled: true,
  },
}, {
  label: '库区类型：',
  prop: 'typeCode',
}, {
  label: '库区名称：',
  prop: 'zoneName',
}, {
  label: '库位编码：',
  prop: 'locationCode',
  component: FormType.INPUT,
}];
export const TABLECOLUMN = [{
  label: '库位编码',
  prop: 'locationCode',
  width: 120,
}, {
  label: '货品质量',
  prop: 'goodsQuality',
  width: 80,
}, {
  label: '可用数量',
  prop: 'amountTxt',
  width: 80,
}, {
  label: '分配数量',
  prop: DICTIONARY.ALLOCATION,
  width: 180,
}, {
  label: '生产日期',
  prop: 'productDate',
  width: 140,
}, {
  label: '到期日期',
  prop: 'expireDate',
  width: 140,
}, {
  label: '入库日期',
  prop: 'createTime',
  width: 140,
}, {
  label: '批次号',
  prop: 'batchNo',
  width: 160,
}];
