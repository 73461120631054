import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 波次单 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12282
 */

export function getWaveOrderDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/get', data);
}
/**
 * @description:分页获取波次单对应出库单列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12284
 */
export function getDeliverOrderFromWaveOrder(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/page_deliver_of_wave', data, {
    params,
  });
}
/**
 * @description:分页获取波次单对应商品信息
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12286
 */
export function getGoodsOrderFromWaveOrder(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/page_goods_of_wave', data, {
    params,
  });
}
/**
 * @description:波次移除
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12346
 */
export function removeWaveOrder(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/remove', data);
}
/**
 * @description:确认缺货
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/21343
 */
export function confirmlackGoods(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/goods_detail/lack_goods/confirm', data);
}
