<template>
  <div class="flex_column full-content">
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      border
      :span-method="handleSpanMethod"
      @update:pagination="handlePaginationChange"
    >
      <template #subtaskStatus="{row}">
        {{ filterLabel(row.subtaskStatus, SUBTASK_STATUS) }}
      </template>
      <!-- <template #action="{row}">
        <nh-button
          v-if="row[DICTIONARY.SUBTASK_STATUS] === PREPARE.value"
          type="text"
          @click="handleAdjustment(row)"
        >
          分配调整
        </nh-button>
      </template> -->
    </PlTable>

    <adjustment-dialog ref="adjustment" @handleSuccess="getTableData" />
  </div>
</template>
<script>
import moment from 'moment';
import { YMDHMS } from '@/constant/timeFormat';
import loadingMixin from '@thales/loading';
import AdjustmentDialog from './components/adjustmentDialog.vue';
import { getSubtasks } from './api';

import {
  DICTIONARY, TABLECOLUMN, SUBTASK_STATUS, PREPARE,
} from './fileds';

export default {
  name: 'WaveOrderDetailTask',
  components: {
    AdjustmentDialog,
  },
  mixins: [loadingMixin],
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      DICTIONARY,
      TABLECOLUMN,
      SUBTASK_STATUS,
      PREPARE,
      tableData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      loading: {
        getTableData: false,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    handlePaginationChange(pagination) {
      this.pagination = {
        ...this.pagination,
        ...pagination,
      };
      this.getTableData();
    },
    async getTableData() {
      const resp = await getSubtasks({ waveId: this.id, ...this.pagination });
      const arr = [];
      resp.records.forEach((item) => {
        const { subtaskRecords, ...subtasks } = item;
        if (subtaskRecords && subtaskRecords.length >= 1) {
          subtaskRecords.forEach((list, index) => {
            const newList = {};
            Object.keys(list).forEach((key) => {
              newList[`subtaskRecords_${key}`] = list[key];
            });
            if (subtaskRecords.length > 1) {
              if (index === 0) {
                newList.rowspan = subtaskRecords.length;
              }
            } else {
              newList.rowspan = 1;
            }
            arr.push({
              ...subtasks,
              ...newList,
              subtaskRecords_operationTime:
                newList.subtaskRecords_operationTime
                && moment(newList.subtaskRecords_operationTime).format(YMDHMS),
            });
          });
        } else {
          arr.push({ ...subtasks, rowspan: 1 });
        }
      });
      this.tableData = arr;
      this.pagination.total = resp.total;
      this.pagination.page = resp.page;
      this.pagination.size = resp.size;
    },
    handleSpanMethod({ row, columnIndex }) {
      if (columnIndex >= 0 && columnIndex <= 8) {
        if (row.rowspan) {
          return [row.rowspan, 1];
        }
        return [0, 0];
      }
      return [1, 1];
    },
    handleAdjustment(row) {
      this.$refs.adjustment.init(row);
    },
    filterLabel(val, arr) {
      const filterArr = arr && arr.find((item) => item.value === val);
      return filterArr ? filterArr.label : '';
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
