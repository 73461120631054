const ORDER_GOODS = 'ORDER';
const PICK_GOODS = 'PICKING';
const SUPPLEMENTARY_NUMBER = 'auxiliaryAmount'; // 辅助数量
const SHIPPED = 'SHIPPED'; // 已发运

export {
  ORDER_GOODS,
  PICK_GOODS,
  SUPPLEMENTARY_NUMBER,
  SHIPPED,
};
