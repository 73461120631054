export const SHOW_COMPONENTS = {
  contiune: '波次任务下发',
  taskDistribution: '是否确认下发？',
};
export const AUTOMATION_PUSH = [
  {
    label: '是',
    value: 'ENABLED',
  },
  {
    label: '否',
    value: 'DISABLED',
  },
];
export const CROSS_DOCKING = [
  {
    label: '越库',
    value: true,
  },
  {
    label: '非越库',
    value: false,
  },
];
export const TEMPERATURE_LAYERS = [
  {
    label: '常温',
    value: 'NORMAL',
  },
  {
    label: '冷藏',
    value: 'REFRIGERATE',
  },
  {
    label: '冷冻',
    value: 'COLD',
  },
  {
    label: '恒温',
    value: 'CONSTANT',
  },
];
export const RULE_STOCK = [
  {
    label: '是',
    value: true,
  },
  {
    label: '否',
    value: false,
  },
];
