import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 总拣相关业务列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12612
 */
export function getSubtasks(params) {
  return wmsPlusHttp.get('/warehouse_management_system/subtasks/total_pick/task_page', {}, {
    params,
  });
}

/**
 * @description: 分配调整接口
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12476
 */
export function getSubtasksAdjust(data, params) {
  return wmsPlusHttp.post('/warehouse_management_system/subtasks/adjust/page', data, {
    params,
  });
}
/**
 * @description: 分配调整确认
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12478
 */
export function confirmAdjust(data, params) {
  return wmsPlusHttp.post('/warehouse_management_system/subtasks/adjust/confirm', data, {
    params,
  });
}
/**
 * @description: 获取库区列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11164
 */
export function getZoneList(data, params) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/page', data, {
    params,
  });
}
