<template>
  <div class="header-component">
    <nh-dialog
      v-model="dialogVisible"
      title="拣货确认"
      custom-class="custom-dialog_560"
      :track="{
        name_zh: `出库管理/波次管理/抽屉-拣货确认`,
        trackName: $route.path
      }"
      @close="handleClose"
    >
      <nh-dialog-form
        ref="ruleForm"
        :model="form"
        label-position="top"
        :rules="rules"
      >
        <nh-dialog-form-item
          :span="24"
          :label="`共${selectedList.length}个波次,请选择拣货温层:`"
          prop="temperatureLayers"
        >
          <el-checkbox-group v-model="form.temperatureLayers">
            <el-checkbox
              v-for="item in zoneTemperature"
              :key="item.value"
              :label="item.value"
            >
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </nh-dialog-form-item>
      </nh-dialog-form>
      <template #footer>
        <div>
          <nh-button
            @click="close"
          >
            取 消
          </nh-button>
          <nh-button
            type="primary"
            :loading="loading.submitForm"
            @click="submitForm"
          >
            确 定
          </nh-button>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import { batchPickConfirm } from '../../api';

export default {
  name: 'BatchPickingConfirm',
  mixins: [loadingMixin],
  emits: ['headleSuccess'],
  data() {
    return {
      dialogVisible: false,
      loading: {
        submitForm: false,
      },
      form: {
        temperatureLayers: [],
      },
      selectedList: [],
      rules: {
        temperatureLayers: [{ required: true, message: '请选择温层', trigger: 'change' }],
      },
    };
  },
  computed: {
    zoneTemperature() {
      return this.$dictionaryFiledMap.ZONE_TEMPERATURE_LAYER_CODE || [];
    },
  },
  methods: {
    init(data) {
      this.dialogVisible = true;
      this.form.temperatureLayers = this.zoneTemperature.map((item) => item.value);
      this.selectedList = data;
    },
    async submitForm() {
      await this.$refs.ruleForm.validate();
      const selectIds = this.selectedList.map((item) => item.id);
      await batchPickConfirm(
        { waveOrderIds: selectIds, temperatureLayers: this.form.temperatureLayers },
      );
      this.$message({ type: 'success', message: '操作成功！' });
      this.$emit('headleSuccess');
      this.close();
    },
    close() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>
