import { waveCopyCodeQuery } from '../../../api';
import { ORDER_GOODS } from '../constant';
import { formRaido } from '../fileds';

/**
 * 根据波次号查询抄码数据和抄码方式
 * @param {*} 波次号
 * @returns formData
 */
async function init(waveOrderId) {
  const {
    copyCodeTypeEnum,
    waveCopyCodeResp,
    orderCopyCodeResp,
    ...info
  } = await waveCopyCodeQuery({
    waveOrderId,
  });
  const form = {
    ...info,
    copyCodeType: copyCodeTypeEnum,
  };
  if (copyCodeTypeEnum === ORDER_GOODS) {
    // 订单抄码数据
    form[formRaido[copyCodeTypeEnum]] = orderCopyCodeResp.orders;
  } else {
    // 波次抄码数据
    form.isExistsDeliveredOrder = waveCopyCodeResp.isExistsDeliveredOrder;
    form[formRaido[copyCodeTypeEnum]] = waveCopyCodeResp.details;
  }
  return form;
}
export default init;
