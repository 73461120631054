export const TEMPERATURE_LAYER = {
  NORMAL: '常温',
  COLD: '冷冻',
  REFRIGERATE: '冷藏',
  CONSTANT: '恒温',
};
export const QUALITY_TYPE = {
  GOOD: '良品',
  BAD: '不良品',
  SCRAP: '报废品',
  FROZEN: '冻结品',
};
// 缺货数据
const OUTOFSTAK = [
  {
    label: '序号',
    type: 'index',
    width: 50,
  },
  {
    label: '货主货品编码',
    prop: 'shipperGoodsCode',
    minWidth: 120,
  },
  {
    label: '货品名称',
    prop: 'goodsName',
    minWidth: 120,
  },
  {
    label: '温层',
    prop: 'temperatureLayer',
    minWidth: 120,
    formatter: (row) => (TEMPERATURE_LAYER[row.temperatureLayer] || '-'),
  },
  {
    label: '规格',
    prop: 'specification',
    minWidth: 120,
  },
  {
    label: '货主',
    prop: 'shipperName',
  },
  {
    label: '货品品质',
    prop: 'quality',
    formatter: (row) => (QUALITY_TYPE[row.quality] || '-'),
  },
  {
    label: '指定批次',
    prop: 'batchNo',
    formatter: (row) => (row.batchNo || '-'),
  },
  {
    label: '指定库位',
    prop: 'locationCode',
    formatter: (row) => (row.locationCode || '-'),
  },
  {
    label: '默认单位',
    prop: 'defaultUnit',
  },
  {
    label: '预约总数量',
    prop: 'bookingNum',
    fixed: 'right',
    minWidth: 95,
  },
  {
    label: '待下发数量',
    prop: 'lackAmount',
    fixed: 'right',
    minWidth: 95,
  },
];
export default OUTOFSTAK;
