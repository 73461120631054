const DICTIONARY = {
  STATUSES: 'statuses',
  SUBTASK_STATUS: 'subtaskStatus',
};
const PREPARE = {
  label: '准备中',
  value: 'PREPARE',
};
const SUBTASK_STATUS = [
  PREPARE,
  {
    label: '作业中',
    value: 'DOING',
  }, {
    label: '已完结',
    value: 'FINISHED',
  }, {
    label: '已关闭',
    value: 'CLOSE',
  }];
export { SUBTASK_STATUS, DICTIONARY, PREPARE };
export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货主货品编号',
  prop: 'shipperGoodsCode',
  minWidth: 120,
  showOverflowTooltip: true,
}, {
  label: '货品名称',
  prop: 'goodsName',
  minWidth: 80,
  showOverflowTooltip: true,
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 80,
  showOverflowTooltip: true,
}, {
  label: '拣货任务单号',
  prop: 'taskNo',
  minWidth: 120,
  showOverflowTooltip: true,
}, {
  label: '状态',
  prop: DICTIONARY.SUBTASK_STATUS,
  minWidth: 80,
  showOverflowTooltip: true,
}, {
  label: '分配批次号',
  prop: 'batchNo',
  minWidth: 120,
  showOverflowTooltip: true,
}, {
  label: '分配库位',
  prop: 'unloadStorehouseCode',
  minWidth: 80,
  showOverflowTooltip: true,
}, {
  label: '分配数量',
  prop: 'unloadAmountStr',
  minWidth: 80,
  formatter: (row) => row.amountStr || row.unloadAmountStr,
  showOverflowTooltip: true,
}, {
  label: '实拣库位',
  prop: 'subtaskRecords_unloadStorehouseCode',
  minWidth: 80,
  showOverflowTooltip: true,
}, {
  label: '实拣批次号',
  prop: 'subtaskRecords_batchNo',
  minWidth: 120,
  showOverflowTooltip: true,
}, {
  label: '实拣量',
  prop: 'subtaskRecords_unloadAmountStr',
  minWidth: 80,
  formatter: (row) => row.subtaskRecords_amountStr || row.subtaskRecords_unloadAmountStr,
  showOverflowTooltip: true,
}, {
  label: '拣货容器',
  prop: 'subtaskRecords_containerCode',
  minWidth: 80,
  showOverflowTooltip: true,
}, {
  label: '拣货人',
  prop: 'subtaskRecords_operatorName',
  minWidth: 80,
  showOverflowTooltip: true,
}, {
  label: '拣货时间',
  prop: 'subtaskRecords_operationTime',
  minWidth: 160,
  showOverflowTooltip: true,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 100,
}];
