import { DateTime } from '@/constant/tableConfig';

export const showAllFields = [
  {
    label: '波次号:',
    value: 'waveOrderNo',
    span: 6,
  },
  {
    label: '货主:',
    value: 'shipperList',
    span: 7,
  },
  {
    label: '温层:',
    value: 'temperatureLayers',
    span: 11,
  },
];

export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  minWidth: 160,
}, {
  label: '商品名称',
  prop: 'goodsName',
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 150,
},
{
  label: '拣货库位',
  prop: 'unloadStorehouseCode',
  minWidth: 150,
},
{
  label: '批次号',
  prop: 'batchNo',
  minWidth: 150,
},
{
  label: '生产日期',
  prop: 'productDate',
  minWidth: 150,
  ...DateTime,
},
{
  label: '预约总数量',
  prop: 'amountStr',
  minWidth: 120,
},
{
  label: '实际拣货量',
  prop: 'newAmount',
  minWidth: 180,
},
];
