import { SHIPPED, ORDER_GOODS } from '../constant';

/**
 * 订单+货品时 当前订单已发运，则该订单的辅助数量列不可编辑。
 * @param {*} 当前订单列
 * @returns Boolean
 */
export const disabledInput = (row) => row.deliverStatus === SHIPPED;

/**
 * 确认按钮启用禁用逻辑
 * @param {*} formData
 * @returns Boolean
 */
export const disabledButton = (formData) => {
  let flag = false;
  if (formData.copyCodeType === ORDER_GOODS) {
    // 订单+货品
    // 波次下所有订单都已发运，则确认按钮置灰不可点击（或者不显示该按钮）
    flag = formData.orderCopyCodeDataList.every((item) => item.deliverStatus === SHIPPED);
  } else {
    // 波次+货品
    // 波次下有订单已发运，则确认按钮置灰不可点击（或者不显示该按钮）
    flag = formData.isExistsDeliveredOrder;
  }
  return flag;
};
