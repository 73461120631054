<template>
  <pl-block>
    <PlForm
      v-model="searchQuery"
      mode="search"
      :fields="serchFields"
      @submit="handleQuery"
      @reset="handleQuery"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="searchQuery.shipperId"
        />
      </template>
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="tableLoading"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
      @selection-change="handleSelectionChange"
      @update:pagination="getTableData"
    >
      <template #tableHeaderRight>
        <nh-button
          type="primary"
          plain
          :track="{
            trackName: '出库管理/波次管理/[批量任务下发]'
          }"
          @click="handleBatchContinue"
        >
          批量任务下发
        </nh-button>
        <nh-button
          type="primary"
          :track="{
            trackName: '出库管理/波次管理/[导出缺货明细]'
          }"
          plain
          @click="chooseExportType"
        >
          导出缺货明细
        </nh-button>
        <nh-button
          v-if="isShuttle"
          type="primary"
          size="small"
          plain
          @click="handlePrintBatchPicking"
        >
          批量打印拣货单
        </nh-button>
        <nh-button
          v-if="isShuttle"
          type="primary"
          size="small"
          plain
          @click="handlePrintBatchOutboundOrder"
        >
          批量打印出库单
        </nh-button>
        <nh-button
          type="primary"
          :track="{
            trackName: '出库管理/波次管理/[拣货确认]'
          }"
          plain
          @click="handlePickingConfirm"
        >
          拣货确认
        </nh-button>
      </template>
      <template #waveOrderNo="{row}">
        <nh-link
          :to="{}"
          :text="row.waveOrderNo"
          @click="handleaveOrderDetails(row)"
        />
      </template>
      <template #waveOrderStatus="{row}">
        <span>{{ compOrderStatus(row.waveOrderStatus) }}</span>
        <span v-if="row.lackFlag" class="out-of-stok">缺</span>
      </template>
      <template #action="{row}">
        <!--
          不同状态显示按钮：
          待下发：【任务下发】、【取消】
          拣货中：【打印拣货单】、【打印分拣单】、【打印送货单】
          拣货完成：【打印拣货单】、【打印分拣单】、【打印送货单】
          已下发：【打印拣货单】、【打印分拣单】、【打印送货单】、【撤回】
          已取消：无
          -->
        <nh-button
          v-if="enableCancel(row)"
          type="text"
          @click="headleContinue(row)"
        >
          任务下发
        </nh-button>
        <nh-button
          v-if="enablePrint(row)"
          type="text"
          @click="handlePrintPicking(row)"
        >
          打印拣货单
        </nh-button>
        <nh-button
          v-if="enablePrint(row)"
          type="text"
          @click="handlePrintSorting(row)"
        >
          打印分拣单
        </nh-button>
        <nh-button
          v-if="enablePrint(row)"
          type="text"
          @click="handlePrintDelivery(row)"
        >
          预打送货单
        </nh-button>
        <nh-button
          v-if="enableCancel(row)"
          type="text"
          :loading="current.cancelId === row.id && loading.handleCancel"
          @click="handleCancel(row)"
        >
          取消
        </nh-button>
        <nh-button
          v-if="enableWithdraw(row)"
          type="text"
          @click="handleWithdraw(row)"
        >
          撤回
        </nh-button>
        <nh-button
          v-if="enableisCopyCode(row)"
          type="text"
          @click="handleRecordCode(row)"
        >
          抄码
        </nh-button>
        <nh-button
          v-if="row.lackFlag"
          type="text"
          style="margin-left: 0;"
          @click="handleAddDistribution(row)"
        >
          缺货货品追加下发
        </nh-button>
      </template>
    </PlTable>
    <wave-order-drawer
      ref="waveOrderDrawer"
      :batch-track="batchTrack"
      @handleSuccess="getTableData"
    />
    <picking-confirm ref="pickingConfirm" @handleDespatch="getTableData" />
    <batchPickingConfirm ref="batchPickingConfirm" @handleSuccess="getTableData" />
    <wave-order-details ref="waveOrderDetails" @onSuccess="getTableData" />
    <record-code ref="recordCodeDrawer" @onSuccess="getTableData" />
    <addDistributionDialog ref="addDistribution" @distributionSuccess="getTableData" />
  </pl-block>
  <div class="dialog-wrapper">
    <nh-dialog
      v-model="dialogVisible"
      title="提示"
      width="30%"
      custom-class="dialog-tips"
      @close="onCancel"
    >
      <div class="dialog_content">
        <p class="tips">
          请选择缺货明细维度：
        </p>
        <el-radio-group v-model="stockExportType">
          <div>
            <el-radio :label="EXPORT_TYPE.GOODS_TYPE">
              按货品
            </el-radio>
            <p class="desc">
              波次状态需为已下发、拣货中、拣货完成
            </p>
          </div>
          <div>
            <el-radio :label="EXPORT_TYPE.ORDER_TYPE">
              按订单
            </el-radio>
            <p class="desc">
              波次状态需为拣货完成
            </p>
          </div>
        </el-radio-group>
      </div>
      <template #footer>
        <div>
          <nh-button
            @click="onCancel"
          >
            取 消
          </nh-button>
          <nh-button
            type="primary"
            @click="onSubmit"
          >
            确 定
          </nh-button>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { h } from 'vue';
import moment from 'moment';
import loadingMixin from '@thales/loading';
import { YMDHMS } from '@/constant/timeFormat';
import OutWarehouse from '@/constant/pageNames/outWarehouse';
import { DeliveryType } from '@/views/print/delivery-list/normal/constant';
import {
  PICKING,
  PICK_COMPLETED,
  DICTIONARY,
  WAITING_DISTRIBUTE,
  ORDER_STATUS,
  TABLECOLUMN,
  serchFields,
  DISTRIBUTE,
  ORDER_GOODS_STATUS,
  EXPORT_TYPE,
} from './fileds';
import mixin from './mixin';
import {
  getWaveOrders, cancelWaveOrder,
  getDeliverOrderFromWaveOrder,
  waveOrderExport,
  subtasksUnfinishedTasks,
  waveOrderReject,
} from './api';
import waveOrderDrawer from './components/waveOrderDrawer.vue';
import PickingConfirm from './components/pickingConfirm/index.vue';
import batchPickingConfirm from './components/batchPickingConfirm/index.vue';
import WaveOrderDetails from './details/index.vue';
import RecordCode from './components/RecordCode/index.vue';
import addDistributionDialog from './components/addDistributionDialog/index.vue';

export default {
  name: OutWarehouse.WAVE_ORDER,
  components: {
    waveOrderDrawer,
    PickingConfirm,
    batchPickingConfirm,
    WaveOrderDetails,
    RecordCode,
    addDistributionDialog,
  },
  mixins: [loadingMixin, mixin],
  data() {
    return {
      batchTrack: '',
      serchFields,
      TABLECOLUMN,
      WAITING_DISTRIBUTE,
      DICTIONARY,
      ORDER_STATUS,
      DISTRIBUTE,
      ORDER_GOODS_STATUS,
      EXPORT_TYPE,
      tableData: [],
      searchQuery: {},
      loading: {
        getTableData: false,
        OrderSubmit: false,
        orderCancel: false,
        handleCancel: false,
      },
      selectIds: [],
      selectedList: [],
      current: {
        cancelId: '',
      },
      dialogVisible: false,
      stockExportType: EXPORT_TYPE.GOODS_TYPE,
    };
  },
  computed: {
    tableLoading() {
      return this.loading.getTableData || this.loading.OrderSubmit || this.loading.orderCancel;
    },
    ...mapState({
      user: ({ user }) => user.accountInfo || {},
    }),
    isShuttle() {
      // 穿梭仓，暂时写死，后期会删除
      // （T10110033,T10200085,T1020007045,T1020007044,T10200083001,T10,T1020007011为测试账号）
      // T10000167（恒鲜仓）
      // T10000142001 萝岗仓库
      const shuttleWarehouseCodes = [
        'T10000142001',
        'T10000142002',
        'T10200085',
        'T10200083001',
        'T1020007044',
        'T1020007045',
        'T10110033',
        'T10000167',
        'T10',
        'T1020007011',
        'T10000142001',
        'T10000185001', // 唐清霖 20220325 10:40 新增租户
        'T10000185', // 上海鲜禾冷链物流有限公司
      ];
      return shuttleWarehouseCodes.includes(this.user.ext.warehouseCode);
    },
    compOrderStatus() {
      return (waveOrderStatus) => {
        const findArr = ORDER_STATUS.find((item) => item.code === waveOrderStatus);
        return findArr ? findArr.label : '';
      };
    },
  },
  activated() {
    this.getTableData();
  },
  methods: {
    // 【打印拣货单】、【打印分拣单】、【打印送货单】展示状态：拣货中、拣货完成、已下发
    enablePrint({ waveOrderStatus }) {
      const printStatus = [PICKING.value, DISTRIBUTE.value, PICK_COMPLETED.value];
      return printStatus.includes(waveOrderStatus);
    },
    // 【撤回】 展示状态：已下发
    enableWithdraw({ waveOrderStatus }) {
      const printStatus = [DISTRIBUTE.value];
      return printStatus.includes(waveOrderStatus);
    },
    // 【任务下发】、【取消】展示状态：待下发
    enableCancel({ waveOrderStatus }) {
      const disableCancelStatus = [WAITING_DISTRIBUTE.value];
      return disableCancelStatus.includes(waveOrderStatus);
    },
    // [抄码] 拣货完成且该波次中有抄码货品时显示。
    enableisCopyCode({ isCopyCode, waveOrderStatus }) {
      const status = [PICK_COMPLETED.value];
      return isCopyCode && status.includes(waveOrderStatus);
    },
    handleShowTag(row) {
      return row[DICTIONARY.WAVE_ORDER_STATUS] === DISTRIBUTE.value
      && row[DICTIONARY.WAVE_ORDER_Goods_STATUS] !== DICTIONARY.ORDER_GOODS_STATUS_NORMAL;
    },
    handleQuery(data) {
      const queryData = { ...data };
      if (!queryData.waveOrderStatus) {
        delete queryData.waveOrderStatus;
      }
      const [startDistributeTime, endDistributeTime] = queryData.distributeTime || [];
      const [startCreateTime, endCreateTime] = queryData.createTime || [];
      this.searchQuery = {
        ...queryData,
        startDistributeTime,
        endDistributeTime,
        startCreateTime,
        endCreateTime,
      };
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await getWaveOrders(pagination, this.searchQuery);
      this.tableData = resp.records.map((item) => ({
        ...item,
        shipperList: (() => {
          if (!item.shipperList) {
            return item.shipperList;
          }
          const list = item.shipperList.map((shipper) => shipper.shipperName);
          return list && list.join('，');
        })(),
        earliestTakeDeliveryTime:
          item.earliestTakeDeliveryTime && moment(item.earliestTakeDeliveryTime).format(YMDHMS),
        createTime: item.createTime && moment(item.createTime).format(YMDHMS),
        distributeTime: item.distributeTime && moment(item.distributeTime).format(YMDHMS),
        shipperConfig: item.shipperList,
      }));
      this.pagination.total = resp.total;
    },
    // 波次下发
    async headleContinue(row) {
      this.batchTrack = '';
      const data = {
        ...row,
        ids: [row.id],
      };
      this.$refs.waveOrderDrawer.init(data);
    },
    // 波次取消
    async handleCancel(row) {
      this.current.cancelId = row.id;
      const article = {
        title: '确认取消该波次',
        main: '取消后波次删除，订单全部回到订单池中。',
      };
      const resp = await this.msgBox(article);
      if (resp) {
        this.orderCancel(row.id);
      }
    },
    handleSelectionChange(selection) {
      this.selectIds = selection.map((item) => item.id);
      this.selectedList = selection;
    },
    onCancel() {
      this.dialogVisible = false;
      this.stockExportType = EXPORT_TYPE.GOODS_TYPE;
    },
    onSubmit() {
      // 按货品维度
      if (this.stockExportType === EXPORT_TYPE.GOODS_TYPE) {
        const hasWaitingDistribute = this.selectedList
          .some((item) => item.waveOrderStatus === WAITING_DISTRIBUTE.value);
        if (hasWaitingDistribute) {
          this.$message({
            type: 'warning',
            message: '不能选择待下发的波次！',
          });
          return;
        }
      } else {
        // 按订单维度，全是拣货完成才能导出
        const isAllPickCompleted = this.selectedList
          .every((item) => item.waveOrderStatus === PICK_COMPLETED.value);
        if (!isAllPickCompleted) {
          this.$message({
            type: 'warning',
            message: '只能选择拣货完成的波次！',
          });
          return;
        }
      }
      this.dialogVisible = false;
      this.handleExport(this.stockExportType);
    },
    chooseExportType() {
      this.dialogVisible = true;
    },
    async handleExport(stockExportType) {
      await waveOrderExport({ waveOrderIds: this.selectIds, stockExportType });
    },
    async orderCancel(id) {
      await cancelWaveOrder({ id });
      this.$message({
        type: 'success',
        message: '已取消!',
      });
      this.getTableData();
    },
    handlePrintPicking(row) {
      const shipperId = row.shipperConfig[0]?.shipperId;
      const routeUrl = this.$router.resolve({
        path: '/print/picking-list',
        query: { waveOrderId: row.id, shipperId },
      });
      window.open(routeUrl.href, '_blank');
    },
    // 批量任务下发
    // 批量下发条件:1、全部为待下发状态
    // 2、只能有一种出库类型，并且该出库类型未配置启用分拣设备
    async handleBatchContinue() {
      this.batchTrack = '批量';
      if (this.selectIds.length === 0) {
        this.$message({ type: 'warning', message: '请先选择至少一条数据！' });
        return;
      }
      // 是否全是待下发状态
      const isWaitingDistribute = this.selectedList
        .every((item) => item.waveOrderStatus === WAITING_DISTRIBUTE.value);
      // 去重后的出库类型
      const setDeliverType = [...new Set(this.selectedList.map((item) => item.deliverType))];
      // 如果全为待下发状态并且只有一种出库类型时
      if (isWaitingDistribute && setDeliverType.length === 1) {
        const data = {
          ids: [...this.selectIds],
          warehouseCode: this.selectedList[0].warehouseCode,
          deliverType: setDeliverType[0],
        };
        this.$refs.waveOrderDrawer.init(data);
      } else {
        this.$message({ type: 'warning', message: '请选择同一出库类型且为待下发状态的数据!' });
      }
    },
    // 批量打印拣货单
    handlePrintBatchPicking() {
      if (this.selectIds.length === 0) {
        this.$message({ type: 'warning', message: '请先选择至少一条数据！' });
        return;
      }
      const routeUrl = this.$router.resolve({
        path: '/print/picking-list',
        query: { waveOrderIds: JSON.stringify(this.selectIds) },
      });
      window.open(routeUrl.href, '_blank');
    },
    // 批量打印出库单
    handlePrintBatchOutboundOrder() {
      const waveOrderStatus = this.selectedList.some(
        (item) => item.waveOrderStatus === WAITING_DISTRIBUTE.value,
      );
      if (this.selectIds.length === 0 || waveOrderStatus) {
        this.$message({ type: 'warning', message: '请选择已下发的波次！' });
        return;
      }
      const routeUrl = this.$router.resolve({
        path: '/print/outbound-order',
        query: { waveOrderIds: this.selectIds },
      });
      window.open(routeUrl.href, '_blank');
    },
    // 批量拣货确认
    async  handlePickingConfirm() {
      if (this.selectIds.length === 0) {
        this.$message({ type: 'warning', message: '请先选择拣货确认的波次' });
        return;
      }
      const waveOrderStatus = this.selectedList.every(
        (item) => item.waveOrderStatus === DISTRIBUTE.value
        || item.waveOrderStatus === PICKING.value,
      );
      if (!waveOrderStatus) {
        this.$message({ type: 'warning', message: '未下发或已拣货完成的波次不能拣货确认' });
        return;
      }
      const waveOrderGoods = this.selectedList.find(
        (item) => item.waveOrderGoodsStatus === ORDER_GOODS_STATUS[2].code,
      );
      if (waveOrderGoods) {
        this.$message({ type: 'warning', message: `波次${waveOrderGoods.waveOrderNo}、补货任务还未结束，无法拣货确认` });
        return;
      }
      if (this.selectedList.length > 1) {
        this.$refs.batchPickingConfirm.init(this.selectedList);
      } else {
        this.$refs.pickingConfirm.init(this.selectedList[0]);
      }
    },
    handlePrintSorting(row) {
      const shipperId = row.shipperConfig[0]?.shipperId;
      const routeUrl = this.$router.resolve({
        path: '/print/sorting-list',
        query: { waveOrderId: row.id, shipperId, waveOrderStatus: row.waveOrderStatus },
      });
      window.open(routeUrl.href, '_blank');
    },
    async handlePrintDelivery(row) {
      const result = await subtasksUnfinishedTasks({ waveId: row.id });
      // 返回值为未完成的任务号
      if (result && result.length > 0) {
        const arr = result.map((item) => `[${item}]`);
        await this.$confirm(`任务号${arr.join('、')}未完成，是否继续打印？`, '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
      }
      const resp = await getDeliverOrderFromWaveOrder({
        page: 1,
        size: 1000,
      }, { id: row.id });
      const ids = resp.records.map((item) => item.id);
      const routeUrl = this.$router.resolve({
        path: '/print/delivery-list',
        query: { ids, type: DeliveryType.BOOKING },
      });
      window.open(routeUrl.href, '_blank');
    },
    // 撤回
    async handleWithdraw(row) {
      await this.$confirm('是否确认撤回该波次？', '波次撤回', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      await waveOrderReject({ rejectIds: [row.id] });
      this.$message({ type: 'success', message: '操作成功！' });
      this.getTableData();
    },
    // 详情
    handleaveOrderDetails({ id }) {
      this.$refs.waveOrderDetails.init(id);
    },
    // 抄码
    handleRecordCode(row) {
      this.$refs.recordCodeDrawer.openDrawer(row);
    },
    // 追加下发缺货货品
    handleAddDistribution(row) {
      this.$refs.addDistribution.showDialog(row);
    },
    async msgBox(article) {
      try {
        await this.$msgbox({
          title: article.title,
          message: h('div', null, [
            h('p', null, article.main),
            h('span', { style: 'color: #c0c4cc' }, article.msg),
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.action {
  padding: 15px 0;
}

.dialog-wrapper {
  :deep(.dialog-tips) {
    width: 400px;
  }
}

.tips {
  margin-bottom: 10px;
}

.desc {
  font-size: 14px;
  margin: -12px 0 25px 28px;
  color: #8f8989;
}

.out-of-stok {
  color: #fff;
  font-size: 12px;
  display: inline-block;
  margin-left: 5px;
  background: #f21313;
  padding: 0 6px;
  border-radius: 10px;
}

.wave-order-no {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
