<template>
  <div class="out-of-wrapper">
    <nh-dialog
      v-model="dialogVisible"
      title="缺货货品追加下发"
      custom-class="out-of-dialog"
      :track="{
        name_zh: '波次管理/缺货出库/缺货货品追加下发',
        trackName: $route.path
      }"
    >
      <p class="tips">
        <i class="icon el-icon-info" />
        表格中展示的是缺货货品
      </p>
      <div class="dialog_content">
        <PlTable
          :loading="loading.getTableData"
          :data="tableData"
          :columns="OUTOFSTAK"
          max-height="250px"
        />
      </div>
      <template #footer>
        <div>
          <nh-button
            type="grey"
            @click="onCancel"
          >
            取 消
          </nh-button>
          <nh-button
            v-loading="loading.distribution"
            type="primary"
            @click="onContinue"
          >
            追加下发
          </nh-button>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import OUTOFSTAK from './fileds';
import {
  lackGoodsList,
  lackLockGoodsReallocate,
} from '../../api';

export default {
  name: 'OutOfStokDialog',
  emits: ['distributionSuccess'],
  data() {
    return {
      tableData: [],
      waveOrderId: '',
      loading: {
        getTableData: false,
        distribution: false,
      },
      OUTOFSTAK,
      dialogVisible: false,
    };
  },
  methods: {
    async showDialog({ id }) { // 取哪个字段需确认
      this.dialogVisible = true;
      this.loading.getTableData = true;
      this.waveOrderId = id;
      try {
        const resp = await lackGoodsList({ waveOrderId: id });
        this.tableData = resp;
      } catch (error) {
        //
      }
      this.loading.getTableData = false;
    },
    onCancel() {
      this.dialogVisible = false;
      this.tableData = [];
    },
    getReallocateDetails() {
      const submitData = this.tableData.map((goods) => ({
        waveOrderGoodsDetailId: goods.id,
        amount: goods.lackAmount,
      }));
      return submitData;
    },
    async onContinue() {
      this.loading.distribution = true;
      try {
        const reallocateDetails = this.getReallocateDetails();
        await lackLockGoodsReallocate({
          waveOrderId: this.waveOrderId,
          reallocateDetails,
        });
        this.loading.distribution = false;
      } catch (error) {
        this.loading.distribution = false;
        return;
      }
      this.dialogVisible = false;
      this.tableData = [];
      this.$message({
        type: 'success',
        message: '追加下发成功!',
      });
      this.$emit('distributionSuccess');
    },
  },
};
</script>
<style lang="scss" scoped>
.out-of-wrapper {
  :deep(.out-of-dialog) {
    width: 65%;

    .table {
      max-height: 300px;
      overflow: auto;
    }
  }
  .tips {
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 2px;
    background: #E6F7FF;
    border: solid 1px #B9E5FF;
    width: 400px;
    .icon {
      color: #1890FF;
    }
  }
}

</style>
