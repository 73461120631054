import { ORDER_GOODS, SUPPLEMENTARY_NUMBER } from '../constant';

function getTotal(num1, num2) {
  return (Math.round((num1 || 0) * 100) + Math.round((num2 || 0) * 100)) / 100;
}

/**
 * 计算当前订单的辅助单位数量的合计和波次总的抄码合计。
 * @param {*} formData
 * @returns formData
 */
export default function computeTotal({
  orderCopyCodeDataList,
  waveCopyCodeDataList,
  copyCodeType,
  ...info
}) {
  const data = info;
  data[SUPPLEMENTARY_NUMBER] = 0;
  // 计算订单抄码 合计
  if (copyCodeType === ORDER_GOODS) {
    data.orderCopyCodeDataList = orderCopyCodeDataList.map((item) => {
      const row = item;
      row[SUPPLEMENTARY_NUMBER] = 0;
      row.details = row.details.map((list) => {
        const { auxiliaryAmount, ...other } = list;
        row[SUPPLEMENTARY_NUMBER] = getTotal(row[SUPPLEMENTARY_NUMBER], auxiliaryAmount);
        if (auxiliaryAmount) {
          return list;
        }
        return {
          auxiliaryAmount: null,
          ...other,
        };
      });
      data[SUPPLEMENTARY_NUMBER] = getTotal(data[SUPPLEMENTARY_NUMBER], row[SUPPLEMENTARY_NUMBER]);
      return row;
    });
  } else {
    // 计算波次抄码明细 合计
    data.waveCopyCodeDataList = waveCopyCodeDataList.map((item) => {
      const { auxiliaryAmount, ...other } = item;
      data[SUPPLEMENTARY_NUMBER] = getTotal(data[SUPPLEMENTARY_NUMBER], auxiliaryAmount);
      if (auxiliaryAmount) {
        return item;
      }
      return {
        auxiliaryAmount: null,
        ...other,
      };
    });
  }
  return {
    ...data,
    copyCodeType,
  };
}
