const DICTIONARY = {
};
const ORDER_DETAILS = {
  label: '波次订单详情',
  value: 'order',
};
const TASK_DETAILS = {
  label: '拣货任务详情',
  value: 'task',
};
const GOODS_DETAILS = {
  label: '波次商品详情',
  value: 'goods',
};
const WAVE_DETAIL = [{
  label: '波次号：',
  keyword: 'waveOrderNo',
}, {
  label: '订单数：',
  keyword: 'deliverOrderNum',
}, {
  label: '拣货任务数：',
  keyword: 'pickTaskNum',
}, {
  label: '品类数量：',
  keyword: 'goodsCategoryNum',
}, {
  label: '商品数量：',
  keyword: 'goodsNum',
}, {
  label: '创建人：',
  keyword: 'createUserName',
}, {
  label: '创建时间：',
  keyword: 'createTime',
},
{
  label: '波次下发时间：',
  keyword: 'distributeTime',
},
{
  label: '总拣完成时间：',
  keyword: 'pickFinishTime',
}];
export {
  DICTIONARY, ORDER_DETAILS, TASK_DETAILS, GOODS_DETAILS, WAVE_DETAIL,
};
