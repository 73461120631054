<template>
  <div class="flex_column full-content">
    <PlTable
      v-model:pagination="pagination"
      v-loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN(GOODS_QUALITY)"
      show-summary
      :summary-method="handleGetSummaries"
    >
      <template #shipperGoodsCode="{row}">
        <p>{{ row.shipperGoodsCode }}</p>
        <el-tag
          v-if="row.handleShowTag"
          size="mini"
          effect="dark"
          type="warning"
        >
          {{ row.handleShowTag }}
        </el-tag>
      </template>
      <template #action="{row}">
        <nh-button
          v-if="row.crossDocking && row.ext?.replenishment"
          type="text"
          @click="handleLost(row)"
        >
          确认缺货
        </nh-button>
      </template>
    </PlTable>
    <el-dialog
      v-model="dialogVisible"
      title="确认缺货"
      width="30%"
    >
      <span>是否确认该越库货品缺货?</span>
      <template #footer class="dialog-footer">
        <el-button @click="dialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="handleSure">
          确 定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import tableSummary from '@/utils/tableSummary';
import { getGoodsOrderFromWaveOrder, confirmlackGoods } from '../../api';

import { DICTIONARY, TABLECOLUMN } from './fileds';
import summaryConfig from './config';

export default {
  name: 'WaveOrderDetailTaskGoods',
  mixins: [loadingMixin],
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      DICTIONARY,
      TABLECOLUMN,
      tableData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      loading: {
        getTableData: false,
      },
      dialogVisible: false,
      detailId: '',
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    async getTableData() {
      const result = await getGoodsOrderFromWaveOrder(this.pagination, { id: this.id });
      this.tableData = result.records.map((item) => ({
        ...item,
        handleShowTag: this.handleShowTag(item),
      }));
      this.pagination.total = result.total;
      this.pagination.page = result.page;
      this.pagination.size = result.size;
    },
    filterLabel(val, arr) {
      const filterAttr = arr && arr.find((item) => item.value === val);
      return filterAttr ? filterAttr.label : '';
    },
    handleShowTag(row) {
      if (row.crossDocking && row.ext?.replenishment) {
        return DICTIONARY.crossDocking;
      }
      if (row.ext?.replenishment) {
        return DICTIONARY.replenishment;
      }
      return null;
    },
    handleGetSummaries({ columns, data }) {
      const config = summaryConfig;
      const sums = [];
      columns.forEach((column, index) => {
        sums[index] = tableSummary({ column, data, config });
      });
      return sums;
    },

    handleLost(row) {
      this.dialogVisible = true;
      this.detailId = row.id;
    },
    async handleSure() {
      this.dialogVisible = false;
      await confirmlackGoods({ id: this.detailId });
      this.$message.success('操作成功!');
      this.getTableData();
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
