<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    title="拣货确认"
    size="1111px"
    :track="{
      name_zh: `出库管理/波次管理/抽屉-拣货确认`,
      trackName: $route.path
    }"
  >
    <nh-dialog-form>
      <nh-dialog-form-item
        v-for="item in showAllFields"
        :key="item.value"
        :label="item.label"
        :span="item.span"
      >
        <el-select
          v-if="item.value==='temperatureLayers'"
          v-model="form.temperatureLayers"
          multiple
          placeholder="请选择"
          @change="getDetail"
        >
          <el-option
            v-for="it in zoneTemperature"
            :key="it.value"
            :label="it.label"
            :value="it.value"
          />
        </el-select>
        <span>
          {{ selectRow[item.value] }}
        </span>
      </nh-dialog-form-item>
    </nh-dialog-form>
    <PlTable
      :data="tableData"
      :loading="loading.getDetail"
      :columns="TABLECOLUMN"
    >
      <template #newAmount="{row}">
        <div class="completed-amount-box">
          <el-input
            v-model="row.completedBigUnitNumber"
            :disabled="row.subtaskStatus === SUBTASK_STATUS.FINISHED
              || row.subtaskStatus === SUBTASK_STATUS.CLOSE"
            @input="(val) => row.completedBigUnitNumber =
              Number(val.replace(/[^0-9]/ig,'')) || null"
            @change="completedAmountChange(row)"
          />
          <span>{{ row.bigUnit }}</span>
          <el-input
            v-model="row.completedSmallUnitNumber"
            :disabled="row.subtaskStatus === SUBTASK_STATUS.FINISHED
              || row.subtaskStatus === SUBTASK_STATUS.CLOSE"
            @input="(val) => row.completedSmallUnitNumber =
              val.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') || null"
            @change="completedAmountChange(row)"
          />
          <span>{{ row.smallUnit }}</span>
        </div>
      </template>
    </PlTable>
    <template #footer>
      <div class="footer">
        <div>
          预约总数量: {{ amountStrTotal }}
        </div>
        <div>
          实际总拣量: {{ completedStrTotal }}
        </div>
        <div>
          <nh-button
            type="primary"
            :loading="loading.handleDespatch"
            @click="handleDespatch"
          >
            确认发货
          </nh-button>
          <nh-button
            @click="handleClose"
          >
            关闭
          </nh-button>
        </div>
      </div>
    </template>
  </nh-drawer>
</template>
<script>
import loadingMixin from '@thales/loading';
import unitNumber from '@/utils/unitNumber';
import { showAllFields, TABLECOLUMN } from './fileds';
import { subtasksPage, singlePickConfirm } from '../../api';

// 类型为出库
const PICKING = 'PICKING';
const SUBTASK_STATUS = {
  PREPARE: 'PREPARE',
  DOING: 'DOING',
  FINISHED: 'FINISHED',
  CLOSE: 'CLOSE',
};
export default {
  name: 'PickingConfirm',
  mixins: [loadingMixin],
  emits: ['handleDespatch'],
  data() {
    return {
      showAllFields,
      TABLECOLUMN,
      SUBTASK_STATUS,
      drawerVisible: false,
      loading: {
        getDetail: false,
        handleDespatch: false,
      },
      selectRow: {},
      tableData: [],
      form: {},
    };
  },
  computed: {
    amountStrTotal() {
      let totalBigAmount = 0;
      let totalSmallAmount = 0;
      this.tableData.forEach((item) => {
        totalBigAmount += unitNumber.calculate({
          smallUnitNumber: item.amount,
          conversionRate: item.conversionRate,
        }).bigUnitNumber * 1000;
        totalSmallAmount += unitNumber.calculate({
          smallUnitNumber: item.amount,
          conversionRate: item.conversionRate,
        }).smallUnitNumber * 1000;
      });
      return `整:${totalBigAmount / 1000} 零:${totalSmallAmount / 1000}`;
    },
    zoneTemperature() {
      return this.$dictionaryFiledMap.ZONE_TEMPERATURE_LAYER_CODE || [];
    },
    completedStrTotal() {
      let totalBigAmount = 0;
      let totalSmallAmount = 0;
      this.tableData.forEach((item) => {
        totalBigAmount += unitNumber.calculate({
          smallUnitNumber: item.newAmount,
          conversionRate: item.conversionRate,
        }).bigUnitNumber * 1000;
        totalSmallAmount += unitNumber.calculate({
          smallUnitNumber: item.newAmount,
          conversionRate: item.conversionRate,
        }).smallUnitNumber * 1000;
      });
      return `整:${totalBigAmount / 1000} 零:${totalSmallAmount / 1000}`;
    },
  },
  methods: {
    init(row) {
      this.selectRow = row;
      this.form.temperatureLayers = this.zoneTemperature.map((item) => item.value);
      this.drawerVisible = true;
      this.getDetail();
    },
    async getDetail() {
      const params = {
        page: 1,
        size: 9999,
      };
      const data = {
        bizId: this.selectRow.id,
        taskTypes: [PICKING],
        temperatureLayers: this.form.temperatureLayers,
      };
      const result = await subtasksPage(params, data);
      this.tableData = result.records.map((item) => ({
        ...item,
        // 实拣数量显示由原来的默认显示预约数量更改为，如果已经完成默认值为已拣数量
        newAmount: item.subtaskStatus === SUBTASK_STATUS.FINISHED ? item.completedAmount
          : item.amount,
        completedBigUnitNumber: unitNumber.calculate({
          smallUnitNumber: item.subtaskStatus === SUBTASK_STATUS.FINISHED ? item.completedAmount
            : item.amount,
          conversionRate: item.conversionRate,
        }).bigUnitNumber,
        completedSmallUnitNumber: unitNumber.calculate({
          smallUnitNumber: item.subtaskStatus === SUBTASK_STATUS.FINISHED ? item.completedAmount
            : item.amount,
          conversionRate: item.conversionRate,
        }).smallUnitNumber,
      }));
    },
    completedAmountChange(row) {
      const newRow = row;
      if (Number(row.completedSmallUnitNumber) >= row.conversionRate) {
        newRow.completedBigUnitNumber += Math.floor(
          Number(row.completedSmallUnitNumber) / row.conversionRate,
        );
        newRow.completedSmallUnitNumber = Number(row.completedSmallUnitNumber) % row.conversionRate;
      }
      newRow.newAmount = unitNumber.getTotal({
        smallUnitNumber: Number(row.completedSmallUnitNumber),
        bigUnitNumber: row.completedBigUnitNumber,
        conversionRate: row.conversionRate,
      });
    },
    async  handleDespatch() {
      const list = this.tableData.filter((item) => (item.subtaskStatus !== SUBTASK_STATUS.FINISHED
      && item.subtaskStatus !== SUBTASK_STATUS.CLOSE));
      const data = {
        waveOrderId: this.selectRow.id,
        subtasks: list.map((item) => ({
          ...item,
          amount: item.newAmount,
        })),
      };
      await singlePickConfirm(data);
      this.handleClose();
      this.$emit('handleDespatch');
    },
    handleClose() {
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.completed-amount-box {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    white-space: nowrap;
    margin: 0 6px;
  }
}

.footer {
  padding: 0 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  display: flex;
  justify-content: space-between;

  span {
    margin: 0 5px;
  }
}
</style>
