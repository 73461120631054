<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    title="波次抄码"
    size="1111px"
    :track="{
      name_zh: `出库管理/波次管理/抽屉-抄码`,
      trackName: $route.path
    }"
  >
    <el-form
      ref="ruleFormRef"
      v-loading="loading.openDrawer"
      :model="formData"
      class="wave-order_recordcode"
    >
      <el-form-item label="抄码方式" label-width="auto">
        <el-radio-group
          v-model="formData.copyCodeType"
          @change="handleChangeRadio"
        >
          <el-radio
            v-for="item in recordType"
            :key="item.value"
            :label="item.value"
            border
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-loading="loading.handleChangeRadio">
        <div
          v-if="disabledSubmit && formData.copyCodeType === PICK_GOODS"
          class="msg"
        >
          该波次有订单已发运，请切换到按订单+货品维度按订单进行调整。
        </div>
        <template v-if="formData.copyCodeType === recordType[0].value">
          <div class="collapse-button">
            <nh-button type="grey" @click="handleChangeActive(true)">
              展开全部订单
            </nh-button>
            <nh-button type="grey" @click="handleChangeActive(false)">
              收起全部订单
            </nh-button>
          </div>
          <PlTable
            :columns="tableColumn"
            class="collapse-table-header"
          />

          <el-collapse
            v-model="activeNames"
          >
            <el-collapse-item
              v-for="(item, i) in formData.orderCopyCodeDataList"
              :key="i"
              :name="i"
            >
              <template #title>
                <div class="collapse-title">
                  <p>
                    <i class="plus">-</i><i class="minus">+</i>
                    <span>订单号：</span><span>{{ item.deliverOrderNo }}</span>
                  </p>
                  <p><span>站点名称：</span><span>{{ item.stationName }}</span></p>
                  <p><span>合计：</span><span>{{ item[SUPPLEMENTARY_NUMBER] }}</span></p>
                </div>
              </template>
              <PlTable
                v-if="item.details"
                :data="item.details"
                :columns="tableColumn"
                :show-header="false"
              >
                <template #[SUPPLEMENTARY_NUMBER]="{row, $index}">
                  <el-form-item
                    v-if="$index >= 0"
                    :prop="`orderCopyCodeDataList.${i}.details.${$index}.${SUPPLEMENTARY_NUMBER}`"
                    :rules="[
                      { required: true, message: ' ', trigger: 'blur' },
                    ]"
                    :error="row.error"
                  >
                    <div class="custom-input-number">
                      <el-input
                        v-model="row[SUPPLEMENTARY_NUMBER]"
                        size="mini"
                        placeholder="请输入"
                        :disabled="disabledInput(item)"
                        @change="(val) => {
                          formData.orderCopyCodeDataList[i]
                            .details[$index][SUPPLEMENTARY_NUMBER] = val === '' ?
                              null: verifyInt(val * 1000) /1000;
                          formData.orderCopyCodeDataList[i]
                            .details[$index].error = null;
                          handleChangeNumber();
                        }"
                      />
                      <span>{{ row.auxiliaryAmountUnit }}</span>
                    </div>
                  </el-form-item>
                </template>
              </PlTable>
            </el-collapse-item>
          </el-collapse>
        </template>
        <PlTable
          v-else
          :data="formData.waveCopyCodeDataList"
          :columns="tableColumn"
        >
          <template #[SUPPLEMENTARY_NUMBER]="{row, $index}">
            <el-form-item
              v-if="$index >= 0"
              :prop="`waveCopyCodeDataList.${$index}.${SUPPLEMENTARY_NUMBER}`"
              :rules="[
                { required: true, message: ' ', trigger: 'blur' },
              ]"
              :error="row.error"
            >
              <div class="custom-input-number">
                <el-input
                  v-model="row[SUPPLEMENTARY_NUMBER]"
                  :disabled="disabledSubmit"
                  @change="(val) => {
                    formData.waveCopyCodeDataList[$index][SUPPLEMENTARY_NUMBER] = val === '' ?
                      null: verifyInt(val * 1000) /1000;
                    formData.waveCopyCodeDataList[$index].error = null;
                    handleChangeNumber();
                  }"
                />
                <span>{{ row.auxiliaryAmountUnit }}</span>
              </div>
            </el-form-item>
          </template>
        </PlTable>
      </div>
    </el-form>
    <template #footer>
      <div class="footer">
        <div>
          抄码合计: {{ formData[SUPPLEMENTARY_NUMBER] }}
        </div>
        <div>
          <nh-button
            type="primary"
            :loading="loading.handleSubmit"
            :disabled="disabledSubmit"
            @click="handleSubmit"
          >
            确认
          </nh-button>
          <nh-button
            @click="handleClose"
          >
            取消
          </nh-button>
        </div>
      </div>
    </template>
  </nh-drawer>
</template>
<script setup>
import {
  ref, reactive,
  defineExpose, defineEmits,
} from 'vue';
import {
  recordType,
  formRaido,
  tableColumn,
} from './fileds';
import {
  SUPPLEMENTARY_NUMBER,
  PICK_GOODS,
} from './constant';
import init from './use/init';
import verifyInt from './unit';

import changeRaido from './use/changeRadio';
import computeTotal from './use/computeTotal';
import { setError, rulesError } from './use/formError';
import { disabledInput, disabledButton } from './use/disabled';
import { waveCopyCodeConfirm } from '../../api';

const emits = defineEmits(['onSuccess']);
const formData = ref({});
const activeNames = ref([]); // 展开数据
let radio = {}; // 储存数据
const loading = reactive({
  openDrawer: false,
  handleChangeRadio: false,
  handleSubmit: false,
});
const disabledSubmit = ref(false);
const drawerVisible = ref(false);

// 缩放
const handleChangeActive = (bool) => {
  if (bool) {
    activeNames.value = formData.value[formRaido[formData.value.copyCodeType]]
      .map((item, index) => index);
  } else {
    activeNames.value = [];
  }
};
const openDrawer = async ({ id }) => {
  drawerVisible.value = true;
  loading.openDrawer = true;
  try {
    const form = await init(id);
    formData.value = computeTotal(form);
    handleChangeActive(true);
    disabledSubmit.value = disabledButton(formData.value);
  } finally {
    loading.openDrawer = false;
  }
};

const ruleFormRef = ref();
const handleClose = () => {
  formData.value = {};
  radio = {};
  activeNames.value = [];
  drawerVisible.value = false;
};

const handleSubmit = async () => {
  if (!rulesError(formData.value)) {
    return;
  }
  await ruleFormRef.value.validate();
  loading.handleSubmit = true;
  try {
    const {
      isExistsError, // 判断是否错误
      waveCopyCodeErrorDataList,
      orderCopyCodeErrorDataList,
    } = await waveCopyCodeConfirm(formData.value);
    if (isExistsError) {
      formData.value = setError(
        formData.value,
        waveCopyCodeErrorDataList,
        orderCopyCodeErrorDataList,
      );
    } else {
      emits('onSuccess');
      handleClose();
    }
  } finally {
    loading.handleSubmit = false;
  }
};

const handleChangeRadio = async (val) => {
  let radioForm = null;
  if (radio[val]) {
    radioForm = radio[val];
  } else {
    loading.handleChangeRadio = true;
    try {
      radioForm = await changeRaido(val, formData.value.waveOrderId);
      radio[val] = radioForm;
    } finally {
      loading.handleChangeRadio = false;
    }
  }
  // 切换后重新计算合计
  formData.value = computeTotal({
    ...formData.value,
    ...radioForm,
  });
  disabledSubmit.value = disabledButton(formData.value);
};

const handleChangeNumber = () => {
  formData.value = computeTotal(formData.value);
};

defineExpose({
  openDrawer,
});
</script>
<style lang="scss" scoped>
.wave-order_recordcode {
  .msg {
    color: #fe5e08;
  }
}

.el-collapse-item {
  p {
    display: flex;
    align-items: center;

    i {
      font-style: normal;
      margin-right: 8px;
    }
  }

  .minus,
  .plus {
    width: 16px;
    height: 16px;
    line-height: 12px;
    font-size: 14px;
    font-weight: 800;
    text-align: center;
    border: 1px solid #dcdee0;
  }

  .minus {
    display: inline-block;
  }

  .plus {
    display: none;
  }
}

.footer {
  padding: 0 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  display: flex;
  justify-content: space-between;

  span {
    margin: 0 5px;
  }
}

.el-collapse-item.is-active {
  .minus {
    display: none;
  }

  .plus {
    display: inline-block;
  }
}

.custom-input-number {
  display: flex;
  align-items: center;

  span {
    padding: 0 8px;
    white-space: nowrap;
  }
}

.collapse-title {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 12px;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(200, 201, 204, 0.5);

  & > p {
    padding-right: 8px;
    flex: 0 0 33%;

    span:first-of-type {
      color: #167aa1;
    }
  }
}

.collapse-button {
  margin-bottom: 16px;
}

</style>
<style lang="scss">
.wave-order_recordcode {
  .el-radio.is-bordered {
    padding: 0 19px 0 11px;
    line-height: 32px;
  }

  .collapse-table-header {
    .el-table__empty-block {
      display: none;
    }
  }

  .el-collapse-item__arrow {
    display: none;
  }

  .el-table__body .el-form-item {
    margin: 0;
  }
}
</style>
