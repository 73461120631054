const DICTIONARY = {
  DELIVER_STATUS: 'deliverStatus',
};
const DELIVER_STATUS = [{
  value: 'PRE_SEND_CAR',
  label: '待派车',
}, {
  value: 'PRE_AUDIT',
  label: '待审核',
}, {
  value: 'PRE_WAVE',
  label: '待组波',
}, {
  value: 'IN_WAVE',
  label: '波次中',
}, {
  value: 'IN_PRODUCTION',
  label: '生产中',
}, {
  value: 'IN_LOADING',
  label: '装车中',
}, {
  value: 'PRE_SHIP',
  label: '待发运',
}, {
  value: 'SHIPPED',
  label: '已发运',
}, {
  value: 'CANCELLED',
  label: '已取消',
}];
export { DICTIONARY, DELIVER_STATUS };
export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '出库单号',
  prop: 'deliverOrderNo',
  minWidth: 80,
}, {
  label: '状态',
  prop: DICTIONARY.DELIVER_STATUS,
  minWidth: 80,
}, {
  label: '品类数量',
  prop: 'goodsCategoryNum',
  minWidth: 80,
}, {
  label: '商品数量',
  prop: 'goodsNum',
  minWidth: 80,
}, {
  label: '线路名称',
  prop: 'lineName',
  minWidth: 120,
}, {
  label: '门店名称',
  prop: 'stationName',
  minWidth: 120,
}, {
  label: '订货时间',
  prop: 'createTime',
  minWidth: 160,
}, {
  label: '预定交货时间',
  prop: 'bookingTime',
  minWidth: 160,
}, {
  label: '实际出库时间',
  prop: 'deliverTime',
  minWidth: 160,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 100,
}];
