<template>
  <div class="adjustment-dialog">
    <nh-drawer
      v-model="dialogVisible"
      :before-close="handleClose"
      destroy-on-close
      title="库存分配调整"
      size="1112px"
      @close="handleClose"
    >
      <PlForm
        v-model="form"
        mode="search"
        :collapse="false"
        :show-collapse-btn="false"
        :fields="formFileds"
        :form-props="{labelPosition: 'top'}"
        submit-btn-text="查询"
        reset-btn-text="重置"
        class="pl-form"
        @submit="getTableData"
        @reset="handleReset"
      >
        <template #typeCode>
          <el-select
            v-model="form.typeCode"
            class="form-input"
            placeholder="请选择"
          >
            <el-option
              v-for="item in storeType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </template>
        <template #zoneName>
          <nh-remote-select
            v-model="form.zoneName"
            class="form-input"
            :remote-method="handleRemoteZone"
            :loading="loading.handleRemoteZone"
          >
            <el-option
              v-for="item in zoneNameList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            />
          </nh-remote-select>
        </template>
      </PlForm>
      <el-table
        v-loading="loading.getTableData"
        :data="tableData"
        height="300px"
        class="table-component"
      >
        <el-table-column type="index" width="50" />
        <el-table-column
          v-for="item in TABLECOLUMN"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.width"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <div v-if="item.prop === DICTIONARY.ALLOCATION">
              <template v-if="scope.row.conversionRate != 1">
                <el-input
                  v-model="scope.row.bigUnitNumber"
                  class="table-input"
                  size="mini"
                  @input="handleInputNum(scope.row, scope.$index)"
                  @blur="handleBlurNum(scope.row, scope.$index)"
                />
                {{ scope.row.bigUnit }}
              </template>
              <el-input
                v-model="scope.row.smallUnitNumber"
                class="table-input"
                size="mini"
                @input="handleInputNum(scope.row, scope.$index)"
                @blur="handleBlurNum(scope.row, scope.$index)"
              />
              {{ scope.row.smallUnit }}
            </div>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <nh-pagination
        class="margin-top_normal"
        v-bind="pagination"
        @change="handlePaginationChange"
      />
      <template #footer>
        <div class="footer">
          <nh-button
            type="grey"
            @click="onClose"
          >
            取 消
          </nh-button>
          <nh-button
            type="primary"
            :loading="loading.onSubmitForm"
            @click="onSubmitForm"
          >
            确定分配
          </nh-button>
        </div>
      </template>
    </nh-drawer>
  </div>
</template>
<script>
import moment from 'moment';
import loadingMixin from '@thales/loading';
import { YMDHMS } from '@/constant/timeFormat';
import unitNumber from '@/utils/unitNumber';
import mixin from '../mixin';
import {
  DICTIONARY, TABLECOLUMN, serchFields, formFileds,
} from './fileds';
import { getSubtasksAdjust, confirmAdjust, getZoneList } from '../api';

export default {
  name: 'WaveOrderDetailTaskDialog',
  mixins: [loadingMixin, mixin],
  emits: ['handleSuccess'],
  data() {
    return {
      DICTIONARY,
      TABLECOLUMN,
      formFileds,
      dialogVisible: false,
      zoneNameList: [],
      form: {},
      tableData: [],
      pagination: {
        page: 1,
        size: 10,
      },
      loading: {
        onSubmitForm: false,
        handleSearch: false,
        handleRemoteZone: false,
      },
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  methods: {
    getLabel(val, arr) {
      const findArr = arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
    init(row) {
      const { id: subtaskId = null, goodsCode = null, goodsName = null } = row;
      this.subtaskId = subtaskId;
      this.dialogVisible = true;
      this.form = {
        ...serchFields,
        goodsCode,
        goodsName,
      };
      this.getTableData();
    },
    handleReset() {
      this.getTableData();
    },
    handlePaginationChange(pagination) {
      this.pagination = {
        ...this.pagination,
        ...pagination,
      };
      this.getTableData();
    },
    async getTableData() {
      this.tableData = [];
      const data = {
        subtaskId: this.subtaskId,
        ...this.form,
      };
      const result = await getSubtasksAdjust(data, this.pagination);
      this.tableData = result.records.map((item) => {
        const { bigUnitNumber, smallUnitNumber } = unitNumber.calculate({
          ...item,
          smallUnitNumber: item.allocateAmount,
        });
        return {
          ...item,
          bigUnitNumber,
          smallUnitNumber,
          goodsQuality: this.getLabel(item.goodsQuality, this.GOODS_QUALITY),
          amountTxt: unitNumber.calculate({ ...item, smallUnitNumber: item.amount }).txt,
          productDate: item.productDate && moment(item.productDate).format(YMDHMS),
          expireDate: item.expireDate && moment(item.expireDate).format(YMDHMS),
          createTime: item.createTime && moment(item.createTime).format(YMDHMS),
        };
      });
      this.pagination.total = result.total;
      this.pagination.page = result.page;
      this.pagination.size = result.size;
    },
    async handleRemoteZone(val) {
      const resp = await getZoneList({ zoneName: val }, { page: 1, size: 99 });
      this.zoneNameList = resp.records.map((item) => ({
        value: item.zoneCode,
        label: item.zoneName,
      }));
    },
    handleInputNum(row, index) {
      const { bigUnitNumber, smallUnitNumber } = unitNumber.calculate(row);
      const total = unitNumber.getTotal(row);
      const {
        bigUnitNumber: maxBigUnitNumber,
        smallUnitNumber: maxSmallUnitNumber,
      } = unitNumber.calculate({ conversionRate: row.conversionRate, smallUnitNumber: row.amount });
      if (total > row.amount) {
        this.tableData[index].bigUnitNumber = maxBigUnitNumber || null;
        this.tableData[index].smallUnitNumber = maxSmallUnitNumber || null;
        this.tableData[index].allocateAmount = row.amount;
      } else {
        this.tableData[index].bigUnitNumber = bigUnitNumber || null;
        this.tableData[index].smallUnitNumber = smallUnitNumber || null;
        this.tableData[index].allocateAmount = total;
      }
    },
    handleBlurNum(row, index) {
      const { bigUnitNumber, smallUnitNumber } = unitNumber.calculate(row);
      this.tableData[index] = {
        ...this.tableData[index],
        bigUnitNumber: bigUnitNumber || null,
        smallUnitNumber: bigUnitNumber || smallUnitNumber ? smallUnitNumber : null,
      };
    },
    async onSubmitForm() {
      const adjustConfirms = [];
      this.tableData.forEach((item) => {
        if (item.allocateAmount) {
          adjustConfirms.push(item);
        }
      });
      await confirmAdjust({ subtaskId: this.subtaskId, adjustConfirms });
      this.$message({ type: 'success', message: '操作成功' });
      this.$emit('handleSuccess');
      this.onClose();
    },
    onClose() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.onClose();
    },
  },
};
</script>
<style lang="scss" scoped>
.adjustment-dialog {
  .table-input {
    width: 50px;
  }
}

.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}

.pl-form {
  margin-left: -24px;
}
</style>
<style lang="scss">
.adjustment-dialog {
  .pl-form {
    .el-form-item {
      margin-left: 24px;
      margin-right: 0;
    }

    .dm-form__btns {
      line-height: 73px;

      .el-button {
        vertical-align: bottom;
      }
    }
  }
}
</style>
